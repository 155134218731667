import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies';
import Vuetify from 'vuetify'
import axios from 'axios'
import App from './App.vue'
import router from './router/router'
import store from './store'
import '@mdi/font/css/materialdesignicons.css'
import 'font-awesome/css/font-awesome.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vuetify/dist/vuetify.min.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css';
import colors from 'vuetify/lib/util/colors'
import * as VueGoogleMaps from 'vue2-google-maps'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import th from 'vuetify/es5/locale/th'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import vuetify from "@/plugins/vuetify";

Vue.use(VueRouter)
Vue.use(VueCookies)
Vue.use(VueTelInputVuetify, {
  vuetify,
})

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  options: {
    customProperties: true,
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#ffd100',
        secondary: colors.grey.darken3,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
      dark: {
        primary: '#ffd100',
        secondary: colors.grey.lighten1,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
    },
  },
  lang: {
    locales: { th },
    current: 'th',
  },
}

Vue.use(Vuetify)
Vue.use(CoolLightBox)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.headers.common.Authorization = `Bearer ${store.state.user.token}`

Vue.use(VueGoogleMaps, {
  load: {
    key: `${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

new Vue({
  vuetify: new Vuetify(opts),
  created() {
    document.title = 'BCRM'
  },
  render: h => h(App),
  router,
  store,
}).$mount('#app')
